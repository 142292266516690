






























































import {
    Component,
    Vue,
    Watch
} from 'vue-property-decorator';

import {
    Auth
} from '@/store/auth'
import {
    Core
} from '@/store/core'
import {
    User
} from '@/store/user'

@Component({
    components: {

    },
    props: {
        color: {
            default: "light",
            validator: function (value) {
                // The value must match one of these strings
                return ["light", "dark"].indexOf(value) !== -1;
            },
        },
    }
})
export default class AdminHome extends Vue {

    private agencyies: any = []
    private response: boolean = false
    private tabs: any = []
    private tab: number = 0
    private search: string = ''
    private blockDialog: boolean = true;
    public async created() {

        await this.run()

    }

    seticon() {
        let tab = this.tab
        if (tab == 0) {
            return 'mdi mdi-account-group'
        } else if (tab == 1) {
            return 'mdi mdi-human-queue'
        } else {
            return 'mdi mdi-account-tie-outline'
        }
    }

    private async getBlock(){
      let user = await User.getProfile();
      this.blockDialog = (user.is_superuser)?false:true
    }

    private async run() {
      await this.getBlock();
        let loader = await this.$loading.show()

        await this.loadTabType()
        await this.loadAgency(this.tabs[0].id)
        this.response = true
        await loader.hide()
    }

    private async loadTabType() {
        this.tabs = await Core.getHttp(`/api/ita/v1/agencytype/`)
        // await this.agencyies = Core.getHttp(`/api/agency`)
    }

    @Watch('tab')
    private async changeTab(val: any) {
        await this.loadAgency(this.tabs[val].id)
    }
    private async loadAgency(id: number) {
        this.agencyies = await Core.getHttp(`/api/ita/v2/agencys/?agency_type=${id}&search=${this.search}`)
    }

}
